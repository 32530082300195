import { useState, useEffect } from 'react';

function useHeader() {
  const [showHeader, setShowHeader] = useState(true);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setPreviousScrollPosition(currentScrollPosition);

      if (currentScrollPosition < previousScrollPosition) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [previousScrollPosition]);

  return { showHeader };
}

export default useHeader;
